import { NavDropdownContext } from "@Context/NavDropdown.context";
import algoliasearch from "algoliasearch";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { InstantSearch, SearchBox } from "react-instantsearch";
import type { SearchState } from "react-instantsearch-core";

import { useClickAway } from "react-use";
import { NavbarVariant } from "types/common";
import { NavbarSearchWrapper, SearchBoxWrapper, SearchResultsDropdown } from "../Navbar.styled";
import NavbarSearchResults from "./NavbarSearchResults";

interface Props {
  setShowBlurredBg: Dispatch<SetStateAction<boolean>>;
  navbarType: NavbarVariant;
  setResMenu: (setResMenu: boolean) => void;
}

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
);

let timerId = undefined;

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }: any) => !params.query || params.query.length < 4)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

export default React.memo(function NavbarSearch({ setShowBlurredBg, navbarType, setResMenu }: Props): ReactElement {
  const { t } = useTranslation("common");
  const { t: tLanding } = useTranslation("Landing");
  const { push } = useRouter();
  const [searchState, setQuery] = useState<string | SearchState>("");
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const searchRef = useRef();
  const { dropDownMenu, setDropDownMenu } = useContext(NavDropdownContext);

  useClickAway(searchRef, () => {
    setOpenSearchDropdown(false);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(true);
    }
  });

  useEffect(() => {
    setShowBlurredBg(openSearchDropdown);
  }, [openSearchDropdown]);

  useEffect(() => {
    if (!dropDownMenu) {
      setOpenSearchDropdown(false);
      setShowBlurredBg(false);
      setResMenu(false);
      setTimeout(() => {
        setDropDownMenu(true);
      }, 500);
    }
  }, [dropDownMenu]);

  const enterClickHandler = (e) => {
    if (e.key === "Enter" && searchState !== undefined && searchState?.trim() !== "") {
      push(`/search?query=${searchState}`);
      setResMenu(false);
      setTimeout(() => {
        setDropDownMenu(false);
      }, 500);
    }
  };

  // TODO: Integrate Top Searches
  // useEffect(() => {
  //   Axios.get(`https://analytics.algolia.com/2/searches?index=subjects`, {
  //     headers: {
  //       "X-Algolia-API-Key": "8fec26544047fae49d1ce15cb8c2e8f2",
  //       "X-Algolia-Application-Id": "CTBDPKOMMJ",
  //     },
  //   }).then(({ data }) => {
  //     console.log("dd", data);
  //   });
  // }, []);

  return (
    <NavbarSearchWrapper
      ref={searchRef}
      onClick={() => {
        setOpenSearchDropdown(true);
        setShowBlurredBg(true);
      }}
    >
      <InstantSearch searchClient={searchClient as any} stalledSearchDelay={1000}>
        <SearchBoxWrapper>
          <SearchBox
            onKeyDown={(e) => enterClickHandler(e)}
            className="search-box"
            ignoreCompositionEvents
            placeholder={tLanding("Search_box_placeholder")}
            queryHook={(query, search) => {
              setQuery(query);
              if (query.length > 3) {
                if (timerId) {
                  clearTimeout(timerId);
                }

                if (query.length > 3) {
                  timerId = setTimeout(() => search(query), 1050);
                }
              }
            }}
            onSubmit={(e) => enterClickHandler(e)}
          />
        </SearchBoxWrapper>
        <SearchResultsDropdown isOpen={openSearchDropdown}>
          <NavbarSearchResults searchState={searchState} />
        </SearchResultsDropdown>
      </InstantSearch>
    </NavbarSearchWrapper>
  );
});
